/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/assets/scss/index.scss';

if (typeof window !== 'undefined') {
	function setDocHeight() {
		document.documentElement.style.setProperty(
			'--vh',
			`${window.innerHeight / 100}px`
		);
	}
	setDocHeight();
	window.addEventListener('resize', setDocHeight);
	window.addEventListener('orientationchange', setDocHeight);
}
